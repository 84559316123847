import { Component, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

// Extend the Window interface to include turnstile
declare global {
  interface Window {
    turnstile: {
      render: (container: HTMLElement, options: { sitekey: string; callback: (token: string) => void }) => void;
    };
  }
}

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ CommonModule, ReactiveFormsModule, RouterModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {

  authService = inject(AuthService);
  router = inject(Router);

  myForm: FormGroup;
  captchaVerified = false;

  showPassword = false; // Default is to hide the password

  constructor(private fb: FormBuilder) {
    this.myForm = this.fb.group({
      password: [''] // Initialize password field
    });
  }

  ngOnInit() {
    // Render the Turnstile CAPTCHA widget
    const container = document.getElementById('turnstile-container');
    if (container && window.turnstile) {
      window.turnstile.render(container, {
        sitekey: '0x4AAAAAAA3ObVERxzC4dNU-', // Replace with your actual site key
        callback: (token: string) => this.setTurnstileToken(token)
      });
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword; // Toggle password visibility
  }
  

  protected loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
    turnstileToken: new FormControl('', Validators.required), // Token from Turnstile

  })

  onForgotPassword() {
    // You can navigate to a "Forgot Password" page
    this.router.navigate(['/forgot-password']);
  }
  


   // Sets the Turnstile token value in the form
   setTurnstileToken(token: string) {
    this.loginForm.controls['turnstileToken'].setValue(token);
    this.captchaVerified = true; // Mark captcha as verified
  }
  
  /* setTurnstileToken(token: string) {
    this.loginForm.patchValue({ turnstileToken: token });
  } */

  onSubmit() {
    if (this.loginForm.valid && this.captchaVerified) {
      this.authService.login(this.loginForm.value).
      subscribe((data: any) => {
        if (data.enabled) {
          if (this.authService.isLoggedIn()) {
            this.router.navigate(['/home']);
          }
        } else {
          alert('Account not verified');
        }
      }, error => {
        alert('Invalid user');
      });
    } else {
      alert('Please complete the CAPTCHA and provide valid credentials.');
    }
  }

}



