<footer class="footer">
  <div class="footer-content">
    <p class="footer-text">
      &copy; 2024 AI-Basil.com. All rights reserved.
    </p>
    <nav class="footer-nav">
      <!--<a (click)="onLogout()" *ngIf="authService.isLoggedIn()" class="footer-link">Logout</a>-->
      <a (click)="onAbout()" class="footer-link">About</a>
      <a (click)="onContact()" class="footer-link">Contact</a>
    </nav>
    <!-- Social Media Icons -->
    <div class="social-icons">
     <!-- <a href="https://facebook.com/yourpage" target="_blank" class="footer-link">
        <i class="fab fa-facebook-f"></i> 
      </a>
      <a href="https://twitter.com/yourprofile" target="_blank" class="footer-link">
        <i class="fab fa-twitter"></i> 
      </a>
      <a href="https://instagram.com/yourprofile" target="_blank" class="footer-link">
        <i class="fab fa-instagram"></i> 
      </a>-->
      <a href="https://www.linkedin.com/company/ai-basil" target="_blank" class="footer-link">
        <i class="fab fa-linkedin-in"></i> <!-- LinkedIn Icon -->
      </a>
    </div>
  </div>
</footer>
