import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { BrandedFoods, foodList, FoodNutrient, Foods, FoundationFoods } from './food-data';
import { Observable } from 'rxjs/internal/Observable';
import { __values } from 'tslib';

@Injectable({
  providedIn: 'root',
})

export class FoodNutrientsService {
  constructor(private httpClient: HttpClient) { }
  url_nutrients = 'https://ai-basil.com/v1/foods/nutrients';
  url_branded_food_nutrients = 'https://ai-basil.com/v1/foods/branded';
  url_branded_food_descriptions = 'https://ai-basil.com/v1/foods/branded-foods/stream';
  url_branded_food_details = 'https://ai-basil.com/v1/foods/branded-foods/all/stream';
  //url_nutrients = 'http://localhost:8080/v1/foods/nutrients';
  //url_branded_food_nutrients = 'http://localhost:8080/v1/foods/branded';
  //url_branded_food_descriptions = 'http://localhost:8080/v1/foods/branded-foods/stream';
  //url_branded_food_details = 'http://localhost:8080/v1/foods/branded-foods/all/stream';


  // Get nutrients by food name
  /* getNutrientsByFoodName(foodName: string): Observable<Foods> {
    const params = new HttpParams().set('foodName', foodName);
    return this.httpClient.get<Foods>(this.url_nutrients, { params });
  } */
  getNutrients(foodName: string): Observable<Foods> {   
    const encodedFoodItem = encodeURIComponent(foodName);

    return this.httpClient.get<Foods>(`${this.url_nutrients}/${encodedFoodItem}`);
  }


// Function to get nutrients for a given food description
getBrandedFoodNutrients(foodDescription: string): Observable<FoodNutrient[]> {
  // Encode the food description for use in the URL
  const encodedFoodDescription = encodeURIComponent(foodDescription);

  // Make the GET request and return the observable
  return this.httpClient.get<FoodNutrient[]>(`${this.url_branded_food_nutrients}/${encodedFoodDescription}`);
}


 // Function to get all food descriptions in a stream
 getFoodDescriptionsStream(): Observable<string[]> {
  return this.httpClient.get<string[]>(this.url_branded_food_descriptions);  // Stream food descriptions from the backend
}


// Function to get branded food details in a stream
getBrandedFoodDetails(description: string): Observable<BrandedFoods> {
  return this.httpClient.get<BrandedFoods>(`${this.url_branded_food_details}/${description}`);
}


}