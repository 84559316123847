<div class="contact-container">
  <h1>Contact Us</h1>
  <p>If you have any questions or feedback, please reach out to us using the form below or through the contact details provided.</p>
  
  <div class="contact-details">
      <h2>Our Contact Information</h2>
      <ul>
        <!--<li><strong>Email:</strong> <a href="mailto:query&#64;ai-basil.com"> query&#64;ai-basil.com</a></li>-->
        <li><strong>Email:</strong> <a href="mailto:query&#64;ai-basil.com"> query&#64;ai-basil.com</a></li>

       <!-- <li><strong>Phone:</strong> (123) 456-7890</li>-->
        <!--<li><strong>Address:</strong> 1234 Main Street, Anytown, USA</li>-->
      </ul>
  </div>

  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="contact-form">
      <h2>Send Us a Message</h2>
    
      <label for="name">Name:</label>
      <input id="name" formControlName="name" type="text" placeholder="Enter your name">

      <label for="email">Email: </label>
      <input id="email" formControlName="email" type="email" placeholder="Enter your email">

      <label for="message">Message:</label>
      <textarea id="message" formControlName="message" rows="5" placeholder="Write your message"></textarea>

      <button type="submit">Send Message</button>
  </form>
</div>
              <!-- Contact Section -->
              <!--section class="contact-section">
                <h1>Contact Us</h1>
                <p>If you have any questions, feel free to reach out to us!</p>
            
                <div class="contact-details">
                  <p><strong>Email:</strong> query&#64;ai-basil.com</p>
                </div>
              </section-->
  
