import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Recipe } from './food-data';

@Injectable({
  providedIn: 'root',
})
export class NFPService {

  private baseUrl = 'https://ai-basil.com/v1/foods';
  private apiUrl = 'https://ai-basil.com/v1/foods/recipes/names'; // API endpoint to fetch recipe names

  //private baseUrl = 'https://ai-basil.com/v1/foods';

  constructor(private httpClient: HttpClient) {}

  /**
   * Save an ingredient to the backend
   * @param ingredient - The ingredient object to save
   * @returns Observable of the response
   */
  saveIngredient(ingredient: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/ingredients`, ingredient).pipe(
      tap((result: any) => {
        console.log('Ingredient saved:', result);
      }),
      catchError((error) => {
        console.error('Error saving ingredient:', error);
        throw error; // Rethrow the error for further handling
      })
    );
  }

  saveRecipe(recipe: { name: string; ingredients: { name: string; amount: number }[]; }): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/recipes/save`, recipe);
  }
  

  getRecipes(): Observable<Recipe[]> {
    return this.httpClient.get<Recipe[]>(this.apiUrl);
  }
}